import React from 'react';
import FooterSection from '../components/FooterSection/Footer';
import BannerSectionHome from "../components/AppSections/HomepageSections/BannerSectionHome";
import OnlineSectionHome from "../components/AppSections/HomepageSections/OnlineSectionHome";
import HowItWorksSectionHome from "../components/AppSections/HomepageSections/HowItWorksSectionHome";
import HowItWorkCashback from "../components/AppSections/HomepageSections/HowItWorkCashback";
import RequestYourKnaekID from "../components/AppSections/HomepageSections/RequestYourKnaekID";

export default function Home() {
    return (
        <div className="homepage-4 pt-5 mt-5">
            <div className="main">
                <BannerSectionHome/>
                <OnlineSectionHome/>
                <HowItWorksSectionHome id={"how-it-works-cb"}/>
                <HowItWorkCashback />
                <RequestYourKnaekID />
                <FooterSection/>
            </div>
        </div>
    );
}
