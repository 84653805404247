const routeMapping = {
  en: {
    vacatures: "vacancies",
    studentenkorting: "student-discounts",
    bijbaan: "side-job",
    "partners-van-knaek": "partners-of-knaek",
    "studentenkorting-geven": "give-student-discounts",
    contact: "contact",
    bestellen: "order",
    checkout: "checkout",
    "download-app": "download-app",
    "algemene-voorwaarden": "terms-conditions",
    privacy: "privacy",
    reviews: "reviews",
    "thank-you-for-rating": "thank-you-for-rating",
    opzeggen: "unsubscribe",
    intro: "intro",
    "student-email": "student-email",
    "free-distribution": "free-distribution",
    verify: "verify",
    webshop: "webshop",
    account: "account",
    "wie-zijn-wij": "about-us",
    FAQ: "faq",
    how_cashback_works: "how-cashback-works",
    404: "404",
    "wachtwoord-vergeten": "forgot-password",
    "auto-login": "auto-login",
    cashback: "cashback",
    "snip-tech-online": "snip-tech-online",
  },
  be: {
    vacancies: "vacatures",
    "student-discounts": "studentenkorting",
    "side-job": "bijbaan",
    "partners-of-knaek": "partners-van-knaek",
    "give-student-discounts": "studentenkorting-geven",
    contact: "contact",
    order: "bestellen",
    checkout: "checkout",
    "download-app": "download-app",
    "terms-conditions": "algemene-voorwaarden",
    privacy: "privacy",
    reviews: "reviews",
    "thank-you-for-rating": "thank-you-for-rating",
    unsubscribe: "opzeggen",
    intro: "intro",
    "student-email": "student-email",
    "free-distribution": "free-distribution",
    verify: "verify",
    webshop: "webshop",
    account: "account",
    "about-us": "wie-zijn-wij",
    faq: "FAQ",
    "how-cashback-works": "how_cashback_works",
    404: "404",
    "forgot-password": "wachtwoord-vergeten",
    "auto-login": "auto-login",
    cashback: "cashback",
    "snip-tech-online": "snip-tech-online",
  },
};

export default routeMapping;
