import React, {useRef} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import {Link} from "react-router-dom";


export default function BecomePartnerCAT() {

    const initStrings = {
        text: stringsTranslate(appWords.cat_our_partners_text),
    }

    return (
        <div className="section section-button-homepage p-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-left d-flex align-items-center justify-content-center mt-2">
                        <Link to="https://airtable.com/app6JUEbw4rLN40uj/shrUUJYFbSPxIfTAS">
                            <div className="text-center section-button-homepage-content">
                                <svg width="74" height="74" viewBox="0 0 32 32" className="svg-white text-center">
                                    <path d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm7,16.5-4.3,4.3-.5.2-.4-.2a.7.7,0,0,1-.01-.99l.01-.01,3.1-3.1H9.5a.7.7,0,0,1,0-1.4H20.9l-3.1-3.1a.7.7,0,0,1-.01-.99l.01-.01a.8.8,0,0,1,.9,0L23,15.5a.7.7,0,0,1,.01.99Z"></path>
                                </svg>
                                <h1 className="text-white text-center ml-3 pt-2">{initStrings.text}</h1>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
