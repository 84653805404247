import React, {useState, useRef, useEffect} from 'react';
import {Animated} from "react-animated-css";
import {checkStatusOrderCall, postHitTrackingCall} from "../../api/ApiCalls";
import {useParams} from "react-router-dom";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {waitAlertFunction} from "../../uitls/AlertFunctions";
import {facebookTrackCompleteRegistration} from "../../uitls/FacebookPixelEvents";
import {tiktokTrackCompleteRegistration} from "../../uitls/TikTokPixelEvents";


export default function OrderDone() {
    const initStrings = {
        playImg: "/assets/images/knaek/emoticon_congratulations-0a9f8fa3dd889c15cb26972d26babee701d176cbb503f0ed6e2d5ad76bf94c5a.png",
        you_are_now_a_member_from_knaek: stringsTranslate(appWords.you_are_now_a_member_from_knaek),
        you_can_log_in_to_knaekapp_with_phone: stringsTranslate(appWords.you_can_log_in_to_knaekapp_with_phone),
        your_knaek_id_is_your_app_with_your_profile: stringsTranslate(appWords.your_knaek_id_is_your_app_with_your_profile),
        enjoy: stringsTranslate(appWords.enjoy),
        download_app: stringsTranslate(appWords.download_app),
        you_pay_now: stringsTranslate(appWords.you_pay_now),
        then: stringsTranslate(appWords.then),
        per_academic_year: stringsTranslate(appWords.per_academic_year),
        per_month: stringsTranslate(appWords.per_month),
        you_can_cancel: stringsTranslate(appWords.you_can_cancel),
        payment_canceled: stringsTranslate(appWords.payment_canceled),
        pay: stringsTranslate(appWords.pay),
        image: "/assets/images/Knaek_mockup.webp",
        "iconList": [
            {
                "id": 3,
                "className": "google-play",
                "link": "https://play.google.com/store/apps/details?id=net.evect.knaek",
                "iconClass": "fab fa-google-play  h1"
            },
            {
                "id": 4,
                "className": "app-store",
                "link": "https://apps.apple.com/nl/app/knaek/id547383255?platform=iphone",
                "iconClass": "fab fa-app-store h1"
            }
        ],
        appleStoreLink: process.env.REACT_APP_APPLE_STORE,
        playStoreLink: process.env.REACT_APP_PLAY_STORE,
    }


    const {token} = useParams();
    const [payment, setPayment]= useState(null)

    useEffect(() => {
        checkStatusOrder()
    }, [])


    return (
    <div className="homepage-4 ">
        <div className="main">
            <div className="container-fluid">
                {(payment && payment.paid) &&  <div className="row">
               <Animated className={"col-md-5 col-lg-5 col-sm-12 bg-white ptb_100 mt-5 d-flex justify-content-center align-items-center flex-wrap flex-column"}
                                                    animationIn="bounceInLeft"
                                                    animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                                                    animationInDelay={100}>
                   <img className="order-done-img mb-3" src={initStrings.playImg} alt=""/>
                   <h2 className="text-knaek-primary text-center">
                       {initStrings.you_are_now_a_member_from_knaek}
                   </h2>
                   <h4 className="text-knaek-title mt-2 text-center">
                       {initStrings.you_can_log_in_to_knaekapp_with_phone}
                   </h4>
                   <h4 className="text-knaek-title mt-2 text-center">
                       {initStrings.your_knaek_id_is_your_app_with_your_profile}
                   </h4>
                   <h2 className="text-knaek-primary mt-2 text-center">
                       {initStrings.enjoy}
                   </h2>

                   <h2 className="text-knaek-primary mt-2 text-center">
                       {initStrings.download_app}
                   </h2>
                   <div className="row mt-4 mt-md-4 justify-content-center w-75">
                       <Animated className={"col-5 col-lg-3 col-md-3"}
                                 animationIn="bounceInLeft"
                                 animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                                 animationInDelay={100}>
                           <section className={"row justify-content-center"}>
                           <a className="badge p-3 bg-white shadow radius-100 "
                              rel="noopener norefferer"
                              href={"https://apps.apple.com/nl/app/knaek/id547383255?platform=iphone"}
                              aria-label="Apple Store Button" target='_blank'>
                               <svg className="" width="35" height="32"
                                    viewBox="0 0 32 32">
                                   <path
                                       d="M4.327,11.8c-2.8,4.7-1,11.8,2,16.1,1.5,2.2,3,4.1,5,4.1h.2a5.6,5.6,0,0,0,2.3-.6,5.787,5.787,0,0,1,2.8-.7,6.7,6.7,0,0,1,2.7.6,4.61,4.61,0,0,0,2.5.7c2.3-.1,3.8-2.2,5-4a17.216,17.216,0,0,0,2.2-4.5h0c.1-.1,0-.2-.1-.3h0a6.9,6.9,0,0,1-4.1-6.2,7,7,0,0,1,3.3-5.8h0c.1,0,.1-.1.1-.2v-.2a7.9,7.9,0,0,0-6-3.2h-.5a10.085,10.085,0,0,0-3.7.9,7.428,7.428,0,0,1-1.8.6,6.711,6.711,0,0,1-1.9-.6,8.38,8.38,0,0,0-3.2-.8h-.1A7.8,7.8,0,0,0,4.327,11.8Z"/>
                                   <path
                                       d="M22.227,0a7.5,7.5,0,0,0-4.8,2.5,6.6,6.6,0,0,0-1.7,5.1.2.2,0,0,0,.2.2h.4a6.2,6.2,0,0,0,4.5-2.3,7.2,7.2,0,0,0,1.7-5.3C22.527.1,22.427,0,22.227,0Z"/>
                               </svg>
                           </a>
                           </section>
                       </Animated>

                       {/*Play reduxStore link show on sm screen */}
                       <Animated
                           className={"col-5 col-lg-3 col-md-3"}
                           animationIn="bounceInRight"
                           animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                           animationInDelay={100}>
                           <section className={"row justify-content-center"}>
                           <a className="badge p-3 bg-white shadow radius-100"
                              rel="noopener norefferer"
                              href={"https://play.google.com/store/apps/details?id=net.evect.knaek"}
                              aria-label="Play Store Button" target='_blank'>
                               <svg className="" width="32" height="32"
                                    viewBox="0 0 28.26 32">
                                   <path
                                       d="M17.83,14.68,3.47.28A2.77,2.77,0,0,1,6.13.41L22.53,10ZM2.15,1.61a3,3,0,0,0-.28,1.25V29.2a2.89,2.89,0,0,0,.28,1.24L16.51,16ZM3.49,31.75A2.92,2.92,0,0,0,4.68,32a2.8,2.8,0,0,0,1.45-.39L22.54,22l-4.7-4.71ZM28.78,13.6h0l-4.55-2.66L19.16,16l5.05,5.06,4.57-2.67a2.81,2.81,0,0,0,0-4.8Z"
                                       transform="translate(-1.87 0)"/>
                               </svg>
                           </a>
                           </section>
                       </Animated>
                   </div>
                    </Animated>

                    <div className="col-md-7 col-lg-7 col-sm-12 bg-gradient ptb_100 d-flex justify-content-center mt-5">
                        <img className={"px-3"} src={initStrings.image} width={450} alt=""/>
                    </div>
                </div>}

                {(payment && !payment.paid) &&  <div className="row">
                    <Animated className={"col-12 bg-white pt-5 mt-5 d-flex justify-content-center align-items-center flex-wrap flex-column"}
                              animationIn="bounceInLeft"
                              animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                              animationInDelay={100}>

                        <h2 className="text-knaek-primary mt-2 text-center">
                            {initStrings.payment_canceled}
                        </h2>

                    </Animated>
                </div>}
            </div>
        </div>
    </div>
    );

    async function checkStatusOrder() {
        waitAlertFunction(stringsTranslate(appWords.processing_your_payment))

        await new Promise(resolve => setTimeout(resolve, 5000));

        try {
            let data = {token};
            let payment = await checkStatusOrderCall(data);
            setPayment(payment);

            if (!payment.success) {
                window.location.href = payment.redirect_url;
            }else {
                facebookTrackCompleteRegistration();
                await tiktokTrackCompleteRegistration()
            }
        } catch (e) {
            console.log(e);

        }

    }


}
