import React, {useState} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import KnaekSecondaryButton from "../../AppComponents/KnaekSecondaryButton";
import {useDispatch, useSelector} from "react-redux";
import {updateOrderKnaekDataRS} from "../../../redux/reduxActions/reduxStoreActions";
import OrderComponent from "../OrderSections/OrderComponent";
import { Link } from "react-router-dom";
// import {getAllIntroByIdCall} from "../../../api/ApiCalls";


export default function IntroKnaekInfoSection({introData}) {


    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedEducationalAssociation, setSelectedEducationalAssociation] = useState(null)


    const orderKnaekDataRS = useSelector(state => state.orderKnaekDataRS);
    const dispatch = useDispatch()
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [wantToGetUpdates, setWantToGetUpdates] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const initStrings = {
        contentTitle1: introData.content.contentTitle1,
        contentText1: introData.content.contentText1,
        contentTitle1EN: introData.content.contentTitle1EN,
        contentText1EN: introData.content.contentText1EN,
        contentButtonUrl1: introData.content.contentButtonUrl1,
        contentButtonLabel1: introData.content.contentButtonLabel1,
        showSection1: introData.content.showSection1,
        contentTitle2: introData.content.contentTitle2,
        contentText2: introData.content.contentText2,
        contentTitle2EN: introData.content.contentTitle2EN,
        contentText2EN: introData.content.contentText2EN,
        contentButtonUrl2: introData.content.contentButtonUrl2,
        contentButtonLabel2: introData.content.contentButtonLabel2,
        showSection2: introData.content.showSection2,
        contentTitle3: introData.content.contentTitle3,
        contentText3: introData.content.contentText3,
        contentTitle3EN: introData.content.contentTitle3EN,
        contentText3EN: introData.content.contentText3EN,
        contentButtonUrl3: introData.content.contentButtonUrl3,
        contentButtonLabel3: introData.content.contentButtonLabel3,
        showSection3: introData.content.showSection3,
        contentTitle4: introData.content.contentTitle4,
        contentText4: introData.content.contentText4,
        contentTitle4EN: introData.content.contentTitle4EN,
        contentText4EN: introData.content.contentText4EN,
        contentButtonUrl4: introData.content.contentButtonUrl4,
        contentButtonLabel4: introData.content.contentButtonLabel4,
        showSection4: introData.content.showSection4,
        showButton1: introData.content.showButton1,
        showButton2: introData.content.showButton2,
        showButton3: introData.content.showButton3,
        showButton4: introData.content.showButton4,

        plyImage: "/assets/images/account/map-popup.png",
        header: stringsTranslate(appWords.intro_welcome_header_Hello),
        content: stringsTranslate(appWords.intro_welcome_content),
        content_1: stringsTranslate(appWords.intro_welcome_content_1),
        content_2: stringsTranslate(appWords.intro_welcome_content_2),
        header2: stringsTranslate(appWords.intro_welcome_header_discount),
        content1: stringsTranslate(appWords.intro_welcome_header_discount_content_1),
        content2: stringsTranslate(appWords.unsubscribe_section_content_2),
        btnText: stringsTranslate(appWords.intro_welcome_btn_text),
        btnText1: stringsTranslate(appWords.intro_welcome_1_btn_text),
        btnText2: stringsTranslate(appWords.intro_welcome_2_btn_text),
        i_agree_to_the_terms_and_conditions_part1: stringsTranslate(appWords.i_agree_to_the_terms_and_conditions_part1),
        i_agree_to_the_terms_and_conditions_link: stringsTranslate(appWords.i_agree_to_the_terms_and_conditions_link),
        i_agree_to_the_terms_and_conditions_part2: stringsTranslate(appWords.i_agree_to_the_terms_and_conditions_part2),
        i_want_to_get_updates: stringsTranslate(appWords.i_want_to_get_updates),
        enter_your_private_email_address: stringsTranslate(appWords.enter_your_private_email_address),
        enter_your_student_email: stringsTranslate(appWords.enter_your_student_email),
        email_at_school: stringsTranslate(appWords.email_at_school),
        email_private: stringsTranslate(appWords.email_private),
        if_you_are_user_in_the_app: stringsTranslate(appWords.if_you_are_user_in_the_app),
        activation_knaek_app: stringsTranslate(appWords.activation_knaek_app),
        the_student_discount_app: stringsTranslate(appWords.the_student_discount_app),
        discount_for_first_year: stringsTranslate(appWords.discount_for_first_year),
        your_first_year_is_on_us: stringsTranslate(appWords.your_first_year_is_on_us),
        to_pay: stringsTranslate(appWords.to_pay),


    }


    return (
        <section className="section">
            <div className="container">
                <div className="row h-100vh justify-content-center">
                    {initStrings.showSection1 && <div className="col-lg-11 col-11 px-3 py-4 shadow">
                        <div className="mr-lg-5 ml-lg-5 text-center align-items-center mb-5 justify-content-center">
                            <h1 className="mt-5 pt-5">{initStrings.contentTitle1}</h1>
                            <p className="mb-5">{initStrings.contentText1}</p>
                            <h1 className="mt-5 pt-5">{initStrings.contentTitle1EN}</h1>
                            <p className="mb-5">{initStrings.contentText1EN}</p>
                            {initStrings.showButton1 && <div className="row justify-content-center ">
                                <div className="col-12">
                                    <div className="pb-3">
                                        <KnaekPrimaryButton
                                            props={""}
                                            onClick={() => {
                                                window.location.href = initStrings.contentButtonUrl1
                                            }}
                                            title={initStrings.contentButtonLabel1}/>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>}


                    {initStrings.showSection2 && <div className="col-lg-11 col-11 bg-gray px-3 shadow">
                        <div className="mr-lg-5 ml-lg-5 text-center align-items-center justify-content-center">
                            <h1 className="mt-5 pt-5">{initStrings.contentTitle2}</h1>
                            <p className="mb-5">{initStrings.contentText2}</p>
                            <h1 className="mt-5 pt-5">{initStrings.contentTitle2EN}</h1>
                            <p className="mb-5">{initStrings.contentText2EN}</p>
                            {initStrings.showButton2 && <div className="row justify-content-center mb-0">
                                <div className="col-12">
                                    <div className="pb-3">
                                        <KnaekSecondaryButton
                                            props={""}
                                            onClick={() => {
                                                window.location.href = initStrings.contentButtonUrl2
                                            }}
                                            title={initStrings.contentButtonLabel2}/>
                                    </div>
                                    {/*<div className="pb-3">*/}
                                    {/*    <KnaekSecondaryButton*/}
                                    {/*        props={""}*/}
                                    {/*        onClick={() => {*/}

                                    {/*        }}*/}
                                    {/*        title={initStrings.btnText2}/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>}
                        </div>
                    </div>}


                    {initStrings.showSection3 && <div className="col-lg-11 col-11 intro-bg px-3 shadow">
                        <div className="mr-lg-5 ml-lg-5 text-center align-items-center mb-5 justify-content-center">
                            <h1 className="mt-5 pt-5 text-white">{initStrings.contentTitle3}</h1>
                            <p className="mb-5 text-white">{initStrings.contentText3}</p>
                            <h1 className="mt-5 pt-5 text-white">{initStrings.contentTitle3EN}</h1>
                            <p className="mb-5 text-white">{initStrings.contentText3EN}</p>
                            {initStrings.showButton3 &&  <div className="row justify-content-center ">
                                <div className="col-12">
                                    <div className="pb-3">
                                        <KnaekSecondaryButton
                                            props={""}
                                            onClick={() => {
                                                window.location.href = initStrings.contentButtonUrl3
                                            }}
                                            title={initStrings.contentButtonLabel3}/>
                                    </div>
                                    {/*<div className="pb-3">*/}
                                    {/*    <KnaekSecondaryButton*/}
                                    {/*        props={""}*/}
                                    {/*        onClick={() => {*/}

                                    {/*        }}*/}
                                    {/*        title={initStrings.btnText2}/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>}
                        </div>
                    </div>}


                    <div className="col-lg-11 col-11 bg-gray px-3 shadow">
                        <div className="mr-lg-5 ml-lg-5 text-center align-items-center mb-5 justify-content-center">
                            <h1 className="mt-5 pt-5">{initStrings.contentTitle4}</h1>
                            <p className="mb-5">{initStrings.contentText4}</p>
                            <h1 className="mt-5 pt-5">{initStrings.contentTitle4EN}</h1>
                            <p className="mb-5">{initStrings.contentText4EN}</p>

                            <div className="col-12">
                                <div className="row justify-content-center align-content-center">
                                    <table className="col-12 table intro-table border radius-15 bg-white">
                                        <tbody className="">
                                        <tr>
                                            <th className="text-left">
                                                <span>{initStrings.activation_knaek_app}</span>
                                                <p>{initStrings.the_student_discount_app}</p>
                                            </th>
                                            <td className="text-right">€30</td>
                                        </tr>
                                        <tr>
                                            <th className="text-left">
                                                <span className="text-success">{initStrings.discount_for_first_year}</span>
                                                <p className="text-success">{initStrings.your_first_year_is_on_us} </p>
                                            </th>
                                            <td className="text-right text-success">-€24.98</td>
                                        </tr>
                                        <tr>
                                            <th className="text-left">
                                                <span>{initStrings.to_pay}</span>
                                            </th>
                                            <td className="text-right">€0.02</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row justify-content-center align-content-center text-left">
                                    <OrderComponent backgroundColor={"bg-transparent"} withStudentEmail={false}/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-11 col-11 bg-white border-top px-3 shadow pb-5">
                        <img className="pb-4 pt-4 pl-3" src="/assets/images/logo-roze.png" style={{width:"96px"}} alt=""/>

                        <div className="col-12">
                            <div className="row justify-content-between">
                                <div className="col-6 justify-content-start">
                                    <h4>Over Knaek</h4>
                                    <ul>
                                        {/*<li>*/}
                                        {/*    <a>Knaek.nl</a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <Link to={"/algemene-voorwaarden"}>Algemene voorwarden</Link>
                                        </li>
                                        <li>
                                            <Link to={"/contact"}>FAQ</Link>
                                        </li>
                                    </ul>

                                </div>
                                <div className="col-6 justify-content-start">
                                    <h4>Download de app</h4>
                                    <ul>
                                        <li>
                                            <a href={"https://play.google.com/store/apps/details?id=net.evect.knaek"}>Voor iOS</a>
                                        </li>
                                        <li>
                                            <a href={"https://apps.apple.com/nl/app/knaek/id547383255?platform=iphone"}>Voor Android</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );


    async function handleInputChange(event) {
        const {name, value} = event.target;
        let updatedData = {...orderKnaekDataRS, [name]: value};
        dispatch(updateOrderKnaekDataRS(updatedData));
    };

    function handleInputChangeRadio(event, value, name) {
        let updatedData = {...orderKnaekDataRS, [name]: value};
        dispatch(updateOrderKnaekDataRS(updatedData));
    };

}

