import React, {useRef, useState} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import {Link} from "react-router-dom";



export default function BannerSectionForCompaies() {

    const initStrings = {
        heading: stringsTranslate(appWords.for_companies),
        heading2: stringsTranslate(appWords.student_discount_via_knaek),
        subheading: stringsTranslate(appWords.why_choose_numerous_national_and_local_companies),
        content: stringsTranslate(appWords.we_use_no_commercial_rates_but_do_ask_involvement),
        playImg: "/assets/images/studentkorting/Knaek-stand-intromarkt.jpg",
        btnContet: stringsTranslate(appWords.contact_us),

    }

    return (
        <section id="Home" className="section for-companies-first-section align-items-start justify-content-center">
            <div className="container">
                <div className="row">
                <div className="col-sm-12">
                    {/*Text under the logo */}
                    <div className="col-lg-12 col-md-12 mb-2 d-flex student-discount-content mt-5 pt-4 pl-3 ">
                        <h1>{initStrings.heading}</h1>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-2 d-flex student-discount-content mt-3 pt-2 pl-3 ">
                        <h2>{initStrings.heading2}</h2>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-2 d-flex student-discount-content pl-0 ">
                        <p className={"ml-3 p-1"}>{initStrings.subheading} {initStrings.content}</p>
                    </div>
                    <div className="col-lg-12 col-md-12 pl-3 mt-4 mb-5 text-center">
                        <KnaekPrimaryButton
                            title={initStrings.btnContet}
                            className={""}
                            onClick={()=> window.location.href= "/contact#contact-form"}
                        />
                    </div>
                </div>
                </div>
                <img src={initStrings.playImg} className={"between-element-companies radius-15 mt-5"} alt={""}/>
            </div>


        </section>
    );
}
