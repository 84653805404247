import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    useHistory,
} from "react-router-dom";

// importing all the pages
import Home from "../pages/Home";
import PartnersOfKnaek from "../pages/StaticPages/PartnersOfKnaek";
import ForCompanies from "../pages/StaticPages/ForCompanies";
import ErrorPage from "../components/ErrorPage/404";
import JobsPage from "../pages/Discounts&Jobs/JobsPage";
import KnaekVacancy from "../pages/StaticPages/KnaekVacancy";
import StoreDiscountsPage from "../pages/Discounts&Jobs/StoreDiscountsPage";
import {
    getAllCitiesCall,
    getAllLocationsCall,
    getAllStoreDiscountsCall
} from "../api/ApiCalls";
import {useDispatch, useSelector} from "react-redux";
import {
    updateCitiesRS,
    // updateJobsRS,
    updateLocationsRS,
    updateStoreDiscountsRS,
} from "../redux/reduxActions/reduxStoreActions";
import ContactPage from "../pages/StaticPages/ContactPage";
import DownloadPage from "../pages/StaticPages/DownloadPage";
import TermsAndConditions from "../pages/StaticPages/TermsAndConditions";
import Privacy from "../pages/StaticPages/Privacy";
import HowCashbackWorks from "../components/AppSections/KnaekContact/HowCashbackWorks";
import FAQ from "../components/AppSections/KnaekContact/FAQ";
import StoreDiscountInfo from "../pages/Discounts&Jobs/Discounts&JobsInfo/StoreDiscountInfo";
import KnaekAboutUs from "../pages/StaticPages/KnaekAboutUs";
import JobInfo from "../pages/Discounts&Jobs/Discounts&JobsInfo/JobInfo";
import ProfilePage from "../pages/Accounts/ProfilePage";
import OrderKnaek from "../pages/Order/OrderKnaek";
import LoginComponent from "../components/AppComponents/LoginComponent";
import { hasAValue } from "../uitls/SharedFunctions";
import KnaekReviews from "../pages/StaticPages/KnaekReviews";
import Unsubscribe from "../pages/Unsubscription/Unsubscribe";
import OrderDone from "../pages/Order/OrderDone";
import UnsubscriptionDone from "../pages/Unsubscription/UnsubscriptionDone";
import Intro from "../pages/IntroKnaek/Intro";
import Checkout from "../pages/Order/Checkout";
import SendStudentEmail from "../pages/Student/SendStudentEmail";
import Forgot from "../pages/Accounts/Forgot";
import SnipTechOnlineDiscountInfo from "../pages/Discounts&Jobs/Discounts&JobsInfo/SniptechOnlineDiscountInfo";
import SnipTechOnlineDiscountsPage from "../pages/Discounts&Jobs/SniptechOnlineDiscountsPage";
import ReactGA from "react-ga4";
import FreeDistributionPage from "../pages/FreeDistribution/FreeDistributionPage";
import ToProfileAfterLogin from "../pages/Accounts/ToProfileAfterLogin";
import ThanksForTheReview from "../components/AppSections/ReviewsPageSection/ThanksForTheReview";
import CouponInfoAndPurchase from "../pages/Webshop/Coupon/CouponInfoAndPurchase";
import EmailVerification from "../pages/EmailVerification/Verification";
import useRouteInterceptor from "../uitls/RouteInterceptor";
import { updateLanguageRS } from "../redux/reduxActions/reduxStoreActions";


export default function MyRouts() {
    const dispatch = useDispatch();

    const citiesRS = useSelector((state) => state.citiesRS);
    const authDataRS = useSelector((state) => state.authDataNEWRS);
    const history = useHistory();
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    let appLanguageRS = useSelector((state) => state.appLanguageRS);

    if (params.get("language") && ["nl", "en"].includes(params.get("language"))) {
        appLanguageRS = params.get("language");
        dispatch(updateLanguageRS(params.get("language")));
        params.delete("language");
        history.replace({ search: params.toString() });
    }


    useEffect(() => {
        getCities();
        getAllDiscounts();
        getAllLocations();
        // getAllVacancies();
    }, []);

    // Function to track page view with Google Analytics
    const trackPageView = (pathname) => {
        ReactGA.send({ hitType: "page_view", page: pathname, title: "GA4" });
    };

    useEffect(() => {
        if (!history) return;
        trackPageView(history.location.pathname);
        const unregister = history.listen((location) => {
            trackPageView(location.pathname);
        });
        return () => {
            unregister();
        };
    }, [history]);
    useRouteInterceptor();


    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route
                path="/studentenkorting/:city_name/:name/:id"
                component={StoreDiscountInfo}
            />
            <Route path="/studentenkorting/:city?" component={StoreDiscountsPage} />
            <Route
                exact
                path="/cashback/studentenkorting/:name/:id"
                component={SnipTechOnlineDiscountInfo}
            />
            <Route exact path="/cashback" component={SnipTechOnlineDiscountsPage}>
                <Redirect to="/cashback/studentenkorting" />
            </Route>
            <Route
                exact
                path="/cashback/studentenkorting"
                component={SnipTechOnlineDiscountsPage}
            />
            {/*<Route path="/bijbaan/:company_name/:name/:id" component={JobInfo} />*/}
            {/*<Route path="/bijbaan/:city?" component={JobsPage} />*/}
            <Route path="/partners-van-knaek" component={PartnersOfKnaek} />
            <Route path="/studentenkorting-geven" component={ForCompanies} />
            <Route path="/vacatures" component={KnaekVacancy} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/bestellen/:token" component={OrderDone} />
            <Route path="/checkout/:token" component={Checkout} />
            <Route path="/bestellen" component={OrderKnaek} />
            <Route path="/download-app" component={DownloadPage} />
            <Route path="/algemene-voorwaarden" component={TermsAndConditions} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/reviews" component={KnaekReviews} />
            <Route path="/thank-you-for-rating" component={ThanksForTheReview} />
            <Route path="/opzeggen/:token" component={UnsubscriptionDone} />
            <Route path="/opzeggen" component={Unsubscribe} />
            <Route path="/intro/:name" component={Intro} />
            <Route path="/student-email/:token" component={SendStudentEmail} />
            <Route path="/free-distribution" component={FreeDistributionPage} />
            <Route path="/verify/:token" component={EmailVerification} />
            <Route
                path="/webshop/:id/:payment_id?"
                component={CouponInfoAndPurchase}
            />

            {hasAValue(authDataRS) && (
                <Route path="/account/:name?" component={ProfilePage} />
            )}
            {!hasAValue(authDataRS) && (
                <Route path="/account/:name?" component={ProfilePage}>
                    <Redirect to="/" />
                </Route>
            )}

            <Route path="/wie-zijn-wij" component={KnaekAboutUs} />
            <Route path="/FAQ" component={FAQ} />
            <Route path="/how_cashback_works" component={HowCashbackWorks} />
            <Route path="/404" component={ErrorPage} />
            <Route path="/wachtwoord-vergeten/:token" component={Forgot} />
            <Route path="/auto-login/:token" component={ToProfileAfterLogin} />
            {/* english routes */}
            <Route exact path="/" component={Home} />
            <Route path="/vacancies" component={KnaekVacancy} />
            <Route
                path="/student-discounts/:city_name/:name/:id"
                component={StoreDiscountInfo}
            />
            <Route path="/student-discounts/:city?" component={StoreDiscountsPage} />
            <Route
                exact
                path="/cashback/student-discounts/:name/:id"
                component={SnipTechOnlineDiscountInfo}
            />
            <Route exact path="/cashback" component={SnipTechOnlineDiscountsPage} />
            <Route
                exact
                path="/cashback/student-discounts"
                component={SnipTechOnlineDiscountsPage}
            />
            <Route path="/side-job/:company_name/:name/:id" component={JobInfo} />
            <Route path="/side-job/:city?" component={JobsPage} />
            <Route path="/partners-of-knaek" component={PartnersOfKnaek} />
            <Route path="/give-student-discounts" component={ForCompanies} />
            <Route path="/vacancies" component={KnaekVacancy} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/order/:token" component={OrderDone} />
            <Route path="/checkout/:token" component={Checkout} />
            <Route path="/order" component={OrderKnaek} />
            <Route path="/download-app" component={DownloadPage} />
            <Route path="/terms-conditions" component={TermsAndConditions} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/reviews" component={KnaekReviews} />
            <Route path="/thank-you-for-rating" component={ThanksForTheReview} />
            <Route path="/unsubscribe/:token" component={UnsubscriptionDone} />
            <Route path="/unsubscribe" component={Unsubscribe} />
            <Route path="/intro/:name" component={Intro} />
            <Route path="/student-email/:token" component={SendStudentEmail} />
            <Route path="/free-distribution" component={FreeDistributionPage} />
            <Route path="/verify/:token" component={EmailVerification} />
            <Route
                path="/webshop/:id/:payment_id?"
                component={CouponInfoAndPurchase}
            />
            <Route path="/account/:name?" component={ProfilePage} />
            <Route path="/about-us" component={KnaekAboutUs} />
            <Route path="/faq" component={FAQ} />
            <Route path="/how-cashback-works" component={HowCashbackWorks} />
            <Route path="/404" component={ErrorPage} />
            <Route path="/forgot-password/:token" component={Forgot} />
            <Route path="/auto-login/:token" component={ToProfileAfterLogin} />
            <Route render={() => <Redirect to="/404" />} />
        </Switch>
    );

    function getCities() {
        if (citiesRS.length === 0) {
            getAllCitiesCall()
                .then((r) => {
                    dispatch(updateCitiesRS(r));
                })
                .catch((error) => { });
        }
    }

    function getAllDiscounts() {
        getAllStoreDiscountsCall()
            .then((data) => {
                dispatch(updateStoreDiscountsRS(data));
            })
            .catch((error) => { });
    }

    function getAllLocations() {
        getAllLocationsCall()
            .then((data) => {
                dispatch(updateLocationsRS(data));
            })
            .catch((error) => { });
    }

    // function getAllVacancies() {
    //     getAllVacanciesCall()
    //         .then((data) => {
    //             dispatch(updateJobsRS(data));
    //         })
    //         .catch((error) => { });
    // }
}

